<template>
  <v-row no-gutters class="flex-column">
    <v-card outlined>
      <v-card-text>
        <v-data-table
          :headers="table.headers"
          :items="allPrizes"
          :loading="allPrizes.length == 0"
        >
          <template v-slot:item.action="{ item }">
            <v-btn icon @click="editData(item)">
              <v-icon>mdi-file-edit</v-icon>
            </v-btn>
            <v-btn icon @click="deleteConfirmation(item)">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- edit dialog -->
    <v-dialog v-model="edit.isEdit" width="800" persistent>
      <v-card dark>
        <v-toolbar tile flat>
          <v-btn
            depressed
            color="accent"
            @click="saveEdit"
            :loading="edit.loading"
            :disabled="edit.loading"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed color="error" @click="edit.isEdit = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <br />
          <v-form ref="edit" v-if="edit.data">
            <v-row>
              <v-col>
                <v-autocomplete
                  label="Event"
                  :items="allEvents"
                  item-text="title"
                  item-value="id"
                  v-model="edit.data.events.id"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field
                  label="Judul"
                  v-model="edit.data.title"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Batas Pemenang"
                  type="number"
                  v-model.number="edit.data.max_winners"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-textarea
              label="Deksripsi"
              v-model="edit.data.description"
            ></v-textarea>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- delete dialog -->
    <v-dialog v-model="del.isDel" width="600" persistent>
      <v-alert
        dark
        prominent
        color="warning"
        icon="mdi-alert"
        close-icon="mb-0"
      >
        <v-row no-gutters class="align-center">
          Yakin Ingin Menghapus Data Ini?
          <v-spacer></v-spacer>
          <v-btn
            text
            large
            @click="handleDelete"
            :loading="del.loading"
            :disabled="del.loading"
          >
            <span>ya</span>
          </v-btn>
          <v-btn large depressed class="ml-2" @click="del.isDel = false">
            <span>batal</span>
          </v-btn>
        </v-row>
      </v-alert>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" multi-line>
      <v-icon left>{{ snackbar.icon }}</v-icon>
      {{ snackbar.text }}

      <template v-slot:action>
        <v-btn text @click="snackbar.show = false">
          <span>close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      table: {
        headers: [
          {
            text: "Event",
            value: "events.title",
          },
          {
            text: "Nama",
            value: "title",
          },
          {
            text: "Deskripsi",
            value: "description",
          },
          {
            text: "Batas Pemenang",
            value: "max_winners",
          },
          {
            text: "Aksi",
            value: "action",
            sortable: false,
          },
        ],
      },
      edit: {
        data: null,
        loading: false,
        isEdit: false,
      },
      del: {
        id: null,
        loading: false,
        isDel: false,
      },
      snackbar: {
        text: null,
        color: null,
        icon: null,
        show: false,
      },
    };
  },
  methods: {
    ...mapActions(["fetchEvents", "updatePrize", "deletePrize", "fetchPrizes"]),
    editData(values) {
      this.edit.data = values;
      this.edit.isEdit = true;
    },
    saveEdit() {
      let data = this.edit.data;
      this.edit.loading = true;
      if (this.updatePrize(data)) {
        this.showNotification("success");
        this.edit.loading = false;
      } else {
        this.showNotification("error");
        this.edit.loading = false;
      }
    },
    deleteConfirmation(values) {
      this.del.id = values.id;
      this.del.isDel = true;
    },
    handleDelete() {
      this.del.loading = true;
      if (this.deletePrize(this.del.id)) {
        this.showNotification("success");
        this.del.loading = false;
        this.del.isDel = false;
      } else {
        this.showNotification("error");
        this.del.loading = false;
      }
    },
    showNotification(status) {
      this.snackbar.show = true;
      switch (status) {
        case "success":
          this.snackbar.text = "Data berhasil diubah";
          this.snackbar.color = "success";
          this.snackbar.icon = "mdi-check-circle";
          break;
        case "error":
          this.snackbar.text = "Data gagal diubah";
          this.snackbar.color = "error";
          this.snackbar.icon = "mdi-close-circle";
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["allPrizes", "allEvents"]),
  },
  mounted() {
    this.fetchPrizes();
    this.fetchEvents();
  },
};
</script>